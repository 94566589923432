import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logoDark from '@/assets/logo-purple.png';
import train from '@/assets/train.json';
import { Button } from '@/components/Elements';

import Loader from './Loader/Loader';

import './style.css';

export const Welcome = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="welcome-section text-center pt-5 pb-5">
          <div className="welcome-container">
            <div className="custom-logo mb-3">
              <img src={logoDark} alt="" />
            </div>
            <h2 className="f-50 bold">Welcome</h2>
            <p className="f-15 pt-4">
              Introducing EusoTrip, the game-changing logistics app designed to optimize your
              work-life balance. As we value the crucial role of drivers and shippers in modern
              society, our platform caters to your unique needs and preferences.
            </p>
            <p className="f-15 pt-4">
              EusoTrip streamlines the connection between drivers and shippers, offering an
              intuitive, user-friendly, and customizable experience. Enjoy the freedom to select
              jobs that suit you, define your own schedule, and manage your workload on your terms.
              Our commitment is to foster a secure and dependable environment for building trust and
              collaboration.
            </p>
            <p className="f-15 pt-4">
              Developed by Eusorone Technologies, Inc in California, EusoTrip's load is booked and
              en route for launch in 2024. Participate in our survey to tailor your maiden voyage,
              and we'll see you at the delivery point.
            </p>
            <div className="welcome-button text-center mt-5">
              <Button
                className="m-auto"
                variant="primary"
                size="lg"
                onClick={() => navigate('/step1')}
              >
                Engage
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
