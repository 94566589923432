import { Question } from '@/types';
import React from 'react';
import MCQ from './MCQ';
import InputQuestion from './InputQuestion';

const QuestionType = ({
  question,
  result,
  setResult,
}: {
  question: Question;
  result: string;
  setResult: (val: string) => void;
}) => {
  if (question?.type === 'mcq') {
    return <MCQ question={question} result={result} setResult={setResult} />;
  }
  if (question?.type === 'inputs') {
    return <InputQuestion question={question} result={result} setResult={setResult} />;
  }
  return <MCQ question={question} result={result} setResult={setResult} />;
};

export default QuestionType;
