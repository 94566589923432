import clsx from 'clsx';
import React, { useState } from 'react';

import { Button } from '@/components/Elements';
import { InputField } from '@/components/Form';
import { useInput } from '@/hooks/useForm';
import { Question } from '@/types';

const MCQ = ({
  question,
  result,
  setResult,
}: {
  question: Question;
  result: string;
  setResult: (val: string) => void;
}) => {
  const [showNeitherQuestion, setShowNeitherQuestion] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const handleValue = (e: any) => {
    setValue(e.target.value);
    setResult(`Neither, ${e.target.value}`);
  };

  const chooseAnswer = (answerId: string) => {
    const isNeither = !!(question.in && question.in === 'catalyst' && answerId === '3');
    setShowNeitherQuestion(isNeither);
    if (isNeither) {
      setResult(`Neither, ${value}`);
    } else {
      setResult(answerId);
    }
  };

  return (
    <div>
      <h2 className="f-22 bold pt-5 pb-4 text-center">{question.question}</h2>
      <div className="surveys-form">
        {question.answers.map((i) => (
          <Button
            key={i.answerId}
            onClick={() => chooseAnswer(i.answerId)}
            variant="inverse"
            size="md"
            className={clsx('survey-button f-16 my-3', i.answerId === result ? 'active' : '')}
          >
            {i.answer}
          </Button>
        ))}
        {showNeitherQuestion && (
          <InputField
            classNames="mb-3"
            value={value}
            onChange={handleValue}
            label="Which compliance authority/ due diligence does your company utilize for onboarding as a 3rd party carrier?"
          />
        )}
      </div>
    </div>
  );
};

export default MCQ;
