import clsx from 'clsx';
import { useState } from 'react';
import * as React from 'react';

import { Button, Dialog } from '@/components/Elements';

export const DarkMode = () => {
  React.useEffect(() => {
    setTimeout(() => {
      const setDarkMode = (active = false) => {
        const wrapper = document.querySelector(':root');
        if (active) {
          wrapper.setAttribute('data-theme', 'dark');
          // localStorage.setItem("theme", "dark");
        } else {
          wrapper.setAttribute('data-theme', 'light');
          // localStorage.setItem("theme", "light");
        }
      };

      const toggleDarkMode = () => {
        const theme = document.querySelector(':root').getAttribute('data-theme');
        // If the current theme is "light", we want to activate dark
        setDarkMode(theme === 'light');
      };
      const initDarkMode = () => {
        const query = window.matchMedia('(prefers-color-scheme: dark)');
        let active = query.matches;
        setDarkMode(active);
        query.addListener((e) => setDarkMode(e.matches));
        const toggleButton = document.querySelector('.js__dark-mode-toggle');
        toggleButton.addEventListener('click', toggleDarkMode);
      };
      initDarkMode();
    }, 100);
  }, []);

  return (
    <>
      <div className="dark-modal">
        <div className="dark-theme-mode">
          <button className="js__dark-mode-toggle dark-mode-toggle" type="button">
            <span className="dark-mode-toggle__icon"></span>
            <span className="dark-mode-toggle__text hidden--visually">dark mode</span>
          </button>
        </div>
      </div>
    </>
  );
};
