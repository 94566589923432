import clsx from 'clsx';
import React, { useState } from 'react';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | 'date' | 'month' | 'time';
  classNames?: string;
  required?: boolean;
  disabled?: any;
  placeholder?: string;
};

export const InputField = (props: InputFieldProps) => {
  const { type = 'text', label, classNames, placeholder, error } = props;

  return (
    <FieldWrapper label={label} error={error}>
      <input
        type={type}
        placeholder={placeholder}
        style={{ padding: '14px 14px 14px 30px' }}
        className={clsx(
          'appearance-none block w-full border placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-gray-500 f-16',
          classNames
        )}
        {...props}
      />
    </FieldWrapper>
  );
};
