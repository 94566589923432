import { Navigate, useRoutes } from 'react-router-dom';

import { Landing } from '@/features/misc';

import { Welcome } from '@/features/pages/Welcome/Welcome';
import { Form1 } from '@/features/pages/SurveyForms/Form1';

export const AppRoutes = () => {
  const commonRoutes = [
    { path: '/', element: <Welcome /> },
    { path: '/step1', element: <Form1 /> },
    // { path: '/', element: <Navigate to={auth.user ? '/app' : '/auth/login'} /> },
  ];

  const element = useRoutes([...commonRoutes]);

  return <>{element}</>;
};
