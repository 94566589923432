import { useState } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import brokerQuestions from '@/assets/questions/brokerQuestions.json';
import catalystQuestions from '@/assets/questions/catalystQuestions.json';
import shipperQuestions from '@/assets/questions/shipperQuestions.json';
import thnk from '@/assets/thank.svg';
import { Button, Dialog } from '@/components/Elements';
import { useDisclosure } from '@/hooks/useDisclosure';
import { Question } from '@/types';

import './form.css';
import StepperQuestions from './StepperQuestions';

export const Form1 = () => {
  const [step1, setStep1] = useState<string>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const navigate = useNavigate();
  const { close, open, isOpen } = useDisclosure();

  const cancelButtonRef = React.useRef(null);

  const handleStep1 = (answer1: string) => () => {
    setStep1(answer1);
    if (answer1 === 'catalyst') setQuestions(catalystQuestions);
    if (answer1 === 'shipper') setQuestions(shipperQuestions);
    if (answer1 === 'broker') setQuestions(brokerQuestions);
  };

  return (
    <>
      <div className="container">
        <div className="surveyform-section pt-5 pb-5">
          {step1 ? (
            <StepperQuestions questionList={questions} setStep1={setStep1} open={open} />
          ) : (
            <div className="survey-container">
              <h2 className="f-30 bold pt-2 pb-4 text-center">CHOOSE YOUR ROLE</h2>
              <div className="surveys-form catalyst">
                <Button
                  onClick={handleStep1('catalyst')}
                  variant="inverse"
                  className="w-90 m-auto mt-3"
                >
                  <h4>Catalyst</h4>
                  <p className="step1p">
                    You are an independent truck driver who owns and operates your own vehicle and
                    will use platform to search for and apply to available jobs.
                  </p>
                </Button>
                <Button
                  onClick={handleStep1('shipper')}
                  variant="inverse"
                  className="w-90 m-auto mt-3"
                >
                  <h4>Shipper</h4>
                  <p className="step1p">
                    Company or organization that originates or sends hazardous materials or
                    petrochemicals to a destination via a transportation provider.
                  </p>
                </Button>
                <Button
                  onClick={handleStep1('broker')}
                  variant="inverse"
                  className="w-90 m-auto mt-3"
                >
                  <h4>Broker</h4>
                  <p className="step1p">
                    Company or individual that acts as an intermediary between the shipper and the
                    carrier to arrange for the transportation of hazardous materials or
                    petrochemicals.
                  </p>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        title=""
        footerButton={
          <Button
            onClick={() => navigate('/')}
            type="button"
            variant="primary"
            size="lg"
            style={{ marginLeft: '5px', marginRight: '5px' }}
            className="mt-3 w-100"
          >
            Done
          </Button>
        }
        hideButton={false}
        isOpen={isOpen}
        onClose={() => {
          navigate('/');
          close();
        }}
        initialFocus={cancelButtonRef}
      >
        <div className="dialog-content">
          <div className="thank-img m-auto">
            <img src={thnk} alt="" />
          </div>
          <h5 className="modal-title  f-24 text-center" id="thankModalLabel">
            Thank You!
          </h5>
          <p className="gray f-14 mb-0 pt-2">
            Your input is essential to the growth of our platform EusoTrip. Check for email updates
            and news on our launch. We look forward to you joining our community.
          </p>
        </div>
      </Dialog>
    </>
  );
};
