import * as React from 'react';

export const useCounter = (total = 15) => {
  const onePercent = 100 / total;
  const [count, setCount] = React.useState<number>(1);
  const [percentage, setPercentage] = React.useState<number>(onePercent);

  const increase = React.useCallback(() => {
    if (count < total) {
      if (count === total - 1) {
        setPercentage(100);
      } else {
        setPercentage((i) => i + onePercent);
      }
      setCount((i) => ++i);
    }
  }, [count]);

  const decrease = React.useCallback(() => {
    if (count > 1) {
      if (count === 2) {
        setPercentage(onePercent);
      } else {
        setPercentage((i) => i - onePercent);
      }
      setCount((i) => --i);
    }
  }, [count]);

  return { count, percentage, increase, decrease };
};
