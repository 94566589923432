import React, { useState } from 'react';

import { Button } from '@/components/Elements';
import { useCounter } from '@/hooks/useCounter';
import { Question } from '@/types';

import QuestionType from './QuestionTypes/QuestionType';
import { axios } from '@/lib/axios';

const StepperQuestions = ({
  questionList,
  setStep1,
  open,
}: {
  questionList: Question[];
  setStep1: (val?: string) => null;
  open: () => null;
}): React.ReactElement => {
  const { count: step, percentage, increase, decrease } = useCounter(questionList.length);
  const [currentQues, setCurrentQuestion] = useState<Question>(questionList[0]);
  const [currentResult, setResult] = useState<string>();
  const [error, setError] = useState<string>('');
  const [results, setResults] = useState(new Map());
  const [loading, setLoading] = useState<boolean>(false);

  const postResults = async (resultList: Map<string, string>) => {
    setLoading(true);
    try {
      const answers = [];
      for (const question of questionList) {
        if (question.type === 'inputs') {
          const answerObject = JSON.parse(resultList.get(question.questionId) ?? '');
          const answerList = Object.entries(answerObject).map(([key, value]) => {
            const answerName = question.answers.find((i) => i.answerId === key)?.answer ?? '';
            return {
              question: answerName,
              answer: value,
            };
          });
          answers.push({ question: question.question, questionType: 'inputs', answer: answerList });
        } else {
          const answerId = resultList.get(question.questionId);
          const answerName =
            question.answers.find((i) => i.answerId === answerId)?.answer ?? answerId;
          answers.push({ question: question.question, questionType: 'mcq', answer: answerName });
        }
      }
      await axios.post('/v1/auth/send-email', { results: answers });
      open();
    } finally {
      setLoading(false);
    }
  };
  const next = () => {
    if (!currentResult) {
      setError('Pick one option');
      return;
    }
    if (currentQues.type === 'inputs') {
      const result = JSON.parse(currentResult);
      const objects = Object.entries(result);
      if (objects.length < currentQues.answers.length) {
        setError('Please fill all the fields');
        return;
      }
      if (Object.values(result).some((i) => !i)) {
        setError('Please fill all the fields');
        return;
      }
    }
    const newResults = new Map([...results, [currentQues.questionId, currentResult]]);
    setResults(newResults);
    increase();
    if (step === total) {
      postResults(newResults);
      return;
    }
    setResult('');
    setCurrentQuestion(questionList[step]);
  };

  const previous = () => {
    if (step === 1) {
      setStep1(undefined);
      return;
    }
    decrease();
    const prevQuestion = questionList[step - 2];
    setCurrentQuestion(prevQuestion);
    setResult(results.get(prevQuestion.questionId));
  };

  const total = questionList.length;

  const handleResult = (val: string) => {
    setError('');
    setResult(val);
  };

  return (
    <div className="survey-container">
      <p className="f-16 text-center">
        {step} of {total}
      </p>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${percentage}%` }}
          aria-valuenow={percentage}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
      <QuestionType question={currentQues} result={currentResult} setResult={handleResult} />

      <div className="surveys-form">
        {error && <p className="text-danger text-center">{error}</p>}

        <div className="form-btns">
          <Button
            isLoading={loading}
            type="button"
            onClick={next}
            variant="primary"
            size="md"
            className="w-90 m-auto"
          >
            {step !== total ? 'Next Question' : 'Submit'}
          </Button>
          <Button onClick={previous} variant="inverse" size="md" className="w-90 m-auto mt-3">
            Previous
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StepperQuestions;
