import React from 'react';
import Lottie from 'react-lottie';

import bg from '@/assets/bg.gif';
import loader from '@/assets/loader.gif';
import train from '@/assets/train.json';

import './loader.css';

const Loader = () => {
  return (
    <div className="loader-div" style={{ backgroundColor: `#c0c0c0` }}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: train,
        }}
        height={'100vh'}
        width={'100vw'}
      />
    </div>
  );
};

export default Loader;
