import clsx from 'clsx';
import React, { useState } from 'react';

import { Button } from '@/components/Elements';
import { InputField } from '@/components/Form';
import { Question } from '@/types';

const MCQ = ({
  question,
  result,
  setResult,
}: {
  question: Question;
  result: string;
  setResult: (val: string) => void;
}) => {
  const [values, setValues] = useState(new Map());

  const handleInput = (answerId: string) => (e: any) => {
    const newValues = new Map([...values, [answerId, e.target.value]]);
    setValues(newValues);
    setResult(JSON.stringify(Object.fromEntries(newValues.entries())));
  };

  return (
    <div>
      <h2 className="f-22 bold pt-5 pb-4 text-center">{question.question}</h2>
      <div className="surveys-form">
        {question.answers.map((i, index) => (
          <InputField
            key={index}
            type={i.type ?? 'number'}
            classNames="mb-3"
            value={values.get(i.answerId) ?? ''}
            onChange={handleInput(i.answerId)}
            label={i.answer}
          />
        ))}
      </div>
    </div>
  );
};

export default MCQ;
